import React from "react"
import axios from "axios"
import loadinggif from "../../images/loading.gif"
import styled from "styled-components"
import CourseContent from "../../components/CourseContentAccordion"
import { useSelector } from "react-redux"
import {
  useGetCoursesQuery,
  useGetUserQuery,
} from "../../components/features/api/authApi"

function Index(props) {
  const [key, setKey] = React.useState("")
  const [, updateState] = React.useState()
  const [courseSlug, setCourseSlug] = React.useState("")
  const token = useSelector((state) => state.user.token)
  //   const [course, setCourse] = React.useState()
  const { data, isLoading, error } = useGetCoursesQuery(key)
  const { userProfile } = useGetUserQuery()
  const course = data && data.courses.find((d) => d.slug === courseSlug)

  React.useEffect(() => {
    const code = props.params["*"]
    const getIndex = props.params && props.params["*"]?.indexOf("/")
    const getLength = props.params["*"]?.length
    const slice = code?.slice(getIndex, getLength)
    const slugSliced = code?.slice(0, getIndex)

    setCourseSlug(slugSliced)
    setKey(slice)
  }, [])

  console.log(course)

  return (
    <>
      {isLoading && (
        <Wrapper>
          <img src={loadinggif} alt="certisured loading gif" />
        </Wrapper>
      )}
      {!isLoading && (
        <Container>
          {token && course ? (
            <CourseContent
              data={course}
              keyId={courseSlug}
              uri={props.uri}
              orderKey={key}
            />
          ) : (
            <h2>You need to login</h2>
          )}
        </Container>
      )}
    </>
  )
}

export default Index

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 900px;
`
const Container = styled.div`
  width: 100%;
  min-height: 900px;
  margin-top: 80px;
`
