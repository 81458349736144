import React from "react"
import styled from "styled-components"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import ReactPlayer from "react-player"
import CourseDetailsTab from "../components/CourseDetailsTab"
import CourseDetailsVideo from "./CourseModules"
import { useSelector } from "react-redux"

function CourseContent({ data, keyId, uri, orderKey }) {

  const player = React.createRef()
  const [ended, setEnded] = React.useState(false)
  const topic = useSelector((state) => state.user.topic)

  const [playing, setPlaying] = React.useState(true)
  const [showNotes, setShowNotes] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [progress, setProgress] = React.useState([])

  let moduleId =
    typeof topic[keyId] !== "undefined" ? topic[keyId]?.module_ids : 0
  let sectionId =
    typeof topic[keyId] !== "undefined" ? topic[keyId]?.section_id : 0
  let insideSectionId =
    typeof topic[keyId] !== "undefined" ? topic[keyId]?.insideSection_id : 0

  let getDescription =
    data.modules[moduleId].course_sections[sectionId].sections[insideSectionId]

  console.log(
    "topic",
    data.modules[moduleId].course_sections[sectionId].sections[insideSectionId]
  )

  const handlePlayPause = () => {
    setPlaying(!playing)
    setShowNotes(!showNotes)
  }

  const handleVideoEnd = (e) => {
    setEnded(true)
    setChecked(true)
  }

  const format = (seconds) => {
    if (isNaN(seconds)) {
      return "00:00"
    }
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()

    const mm = date.getUTCMinutes()
    const ss = date.getUTCSeconds().toString().padStart(2, "0")

    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
    }

    return `${mm}:${ss}`
  }

  const elapsedTime = format(progress)

  return (
    <Container>
      <Wrapper>
        <div className="right_section">
          <div className="right_section_description">
            <div className="video_player">
              {getDescription.type === "video" ? (
                <div className="video_player_section">
                  <div className="heading">
                    <h1 className="heading_title">{data.title}</h1>
                  </div>
                  <div className="player-wrapper">
                    <ReactPlayer
                      ref={player}
                      className="react-player"
                      url={`https://player.vimeo.com/video/${
                        data.modules && getDescription.description
                      }`}
                      width="100%"
                      height="100%"
                      playing={playing}
                      onEnded={() => handleVideoEnd}
                    />
                  </div>
                </div>
              ) : getDescription.type === "quiz" ? (
                <div className="quiz-wrapper">
                  <ReactMarkdown
                    children={getDescription.description}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              ) : getDescription.type === "document" ||
                getDescription.type === "project" ||
                getDescription.type === "caseStudy" ? (
                <div className="document-wrapper">
                  <ReactMarkdown
                    children={getDescription.description}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <CourseDetailsTab
              player={player}
              link={false}
              ReadMoreProps={true}
              OnPlayPause={handlePlayPause}
              showNotes={showNotes}
              data={data}
              keyId={keyId}
              elapsedTime={elapsedTime}
              uri={uri}
            />
          </div>
        </div>
        <div className="left_section">
          <div className="left_section_section">
            <CourseDetailsVideo
              data={data}
              ended={ended}
              checked={checked}
              keyId={keyId}
              course_id={data.id}
              uri={uri}
              category="paid"
              orderKey={orderKey}
              title={data.title}
            />
          </div>
        </div>
      </Wrapper>
    </Container>
  )
}

export default CourseContent

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  width: 100%;
  height: auto;
`

const Wrapper = styled.div`
  grid-area: 1/1/2/12;
  width: 100%;
  display: flex;
  height: auto;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    grid-area: 1/1/2/12;
  }
  .quiz-wrapper {
    width: 100%;
  }
  iframe {
    border-radius: 10px;
    height: 75vh;
    width: 100%;
    @media (max-width: 767px) {
      height: 40vh;
    }
  }
  .left_section {
    width: 25%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 5px;
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
    width: 100%;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .document-wrapper {
    background-color: #fff;
    width: 80%;
    border-radius: 6px;
    padding: 0 1rem;
    height: 75vh;
    overflow-y: auto;
    @media (max-width: 767px) {
      height: 40vh;
      width: 100%;
    }
    @media (max-width: 479px) {
      margin-top: 20px;
      h1 {
        font-size: 1.2rem;
        font-weight: 600;
      }
      h2 {
        font-size: 1.125rem;
        font-weight: 500;
      }
      h3 {
        font-size: 1rem;
        font-weight: 500;
      }
      li,
      p {
        font-size: 0.9rem;
      }
    }
    @media (max-width: 991px) {
      grid-area: 5/3/6/10;
    }

    @media (max-width: 767px) {
      grid-area: 5/2/6/11;
    }
    h1 {
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0.07em;
      color: var(--secondaryColor);
    }
    h2 {
      font-weight: 500;
      font-size: 22px;
      letter-spacing: 0.07em;
      color: var(--secondaryColor);
    }
    h3 {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.07em;
      color: var(--secondaryColor);
    }
    h4 {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.07em;
      color: var(--secondaryColor);
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.07em;
      color: var(--secondaryColor);
    }
    li {
      margin: 0.75rem 0;
      letter-spacing: 0.07em;
    }
    blockquote {
      border-left: 6px solid var(--purpleColor);
      margin: 0;
      padding-left: 1rem;
    }
    a {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0.07em;
      @media (max-width: 550px) {
        font-size: 17px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.07em;
      margin: 1rem 0;
      @media (max-width: 550px) {
        font-size: 17px;
      }
      @media (max-width: 479px) {
        margin: 1rem 0;
      }
    }

    img {
      width: 300px;
      margin: 1rem 0;
      border-radius: 6px;
    }
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .right_section {
    width: 75%;
    position: relative;
    padding-right: 0;
  }
  .MuiTypography-body1 {
    width: 100%;
    font-weight: 500;
    font-size: 18px;

    color: #151515;
  }
  .topic_heading {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -50px;
    @media (max-width: 767px) {
      margin-top: 0px;
      padding-bottom: 10px;
    }
  }
  .content_heading {
    display: flex;
    border-bottom: 1.5px solid rgba(21, 21, 21, 0.22);
    h4 {
      font-weight: 600;
      font-size: 22px;
      margin: 20px 15px;
      /* identical to box height */

      color: #151515;
    }
  }
  /* .MuiPaper-elevation1{
       box-shadow : none;
   } */
  .subtopic_li {
    width: 100%;
    list-style: none;
    font-size: 16px;
    font-family: var(--family);
    padding: 10px 0;
    font-weight: 400;
    color: #151515;
    opacity: 0.5;
    padding-right: 10px;
  }
  .subtopic_li.active {
    /* or 131% */
    color: #151515;
    opacity: 1;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
      font-size: 22px;
      color: var(--secondaryColor);
      position: absolute;
    }
  }
  input {
    width: 25px;
    height: 25px;
    border: 1px solid #000;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input:checked {
    background: var(--thirdColor);
    border: none;
  }
  input.disabled {
    border: 1px solid #15151557 !important;
  }
  .lessons_panel,
  .lessons_panel_active {
    display: flex;
    margin-bottom: 5px;
    padding-left: 5px;
    color: var(--secondaryColor);
    padding-left: 16px;
    font-weight: 400;
    align-items: center;
    justify-content: space-between;
  }

  .right_section_description {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      display: flex;
      width: 80%;
      justify-content: flex-start;
      flex-direction: column;
      @media (max-width: 767px) {
        width: 100%;
      }
      .heading_title {
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 2%;
        color: #fff;
        @media (max-width: 767px) {
          font-size: 0.9rem;
          color: #000;
          font-weight: 600;
        }
      }
      .heading_h2 {
        font-weight: 600;
        font-size: 16px;
        text-align: start !important;
        color: #fff !important;
        @media (max-width: 767px) {
          color: #000 !important;
          font-size: 14px;
        }
      }
    }
  }
  .video_player {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #000;
    padding-bottom: 4%;
    padding-top: 2%;
    .video_player_section {
      width: 80%;
    }
  }
  ul {
    padding: 0;
    margin: 0;
  }
  .left_section_heading {
    width: 100%;
    background-color: var(--thirdColor);
    display: flex;
    justify-content: center;
  }
  h2 {
    text-align: center;
  }

  @media (max-width: 767px) {
    .left_section {
      display: none;
      width: 0px;
    }
    .right_section {
      width: 100%;
      padding-left: 0;
      margin: 0 20px;
    }
    h3 {
      font-size: 18px;
    }
    .video_player {
      margin-bottom: 20px;
      background: none;
    }
    .video_player_section {
      width: 100% !important;
    }
  }
`
